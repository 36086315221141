<template>
    <IPopupMenu
        v-if="languageItems?.length??0 > 0"
        trigger="click"
    >
        <template v-slot:trigger>
            <a class="mdi mdi-web" :class="triggerClass" v-tooltip="trans('dashboard.menu.tooltip.language_switch')">
                <span :class="'fi fi-' + (languageCurrent.flag??languageCurrent.language)"></span>
            </a>
        </template>
        <template v-slot:items>
            <template v-for="item in languageItems"
                      v-bind:key="item.language"
            >
                <li @click="switchLanguage(item.language)"
                    v-tooltip="item.tooltip"
                >
                    <span :class="'fi fi-' + (item.flag??item.language)"></span>
                    <span class="langtext">{{ trans('language.' + item.language) }}</span>
                </li>
            </template>
            <li v-if="showMoreLanguages" 
                @click="navigateTo(route('backend.config.general.index') + '?tab=6')">
                <span class="langtext"><i>{{ trans('dashboard.menu.label.more_languages') }}</i></span>
            </li>
        </template>
    </IPopupMenu>
</template>

<script setup>
import { ref, inject, onMounted, nextTick } from 'vue';
import { getLocale } from 'matice';
import { handleErrors, navigateTo } from '@/integeri-helper.js';
import IPopupMenu from '@/Components/Common/IPopupMenu.vue';
import api from '@/api';

const emitter = inject('emitter');
let languageItems = ref(null);
let languageCurrent = ref({flag:'de'});

defineProps({
    triggerClass: {
        type: String,
        required: false,
        default: '',
    },
    showMoreLanguages: {
        type: Boolean,
        required: false,
        default: false,
    },
});
emitter.on('popupmenu:opened', async () => {
    emitter.emit('popupmenu:move', [100, 100]);
});
emitter.on('languagemenu:update', () => {
    loadLanguages();
});
const switchLanguage = (lang) => {
    document.cookie = `lang=${lang};path=/;max-age=31536000`;
    let x = document.location.href.split('?');
    let params = 'lang=' + lang;
    if (x.length > 1) {
        let rest = x[1].replace(/lang=\w\w/, '');
        if (rest.length > 0) {
            params = rest + '&' + params;
        }
    }
    document.location.href = x[0] + '?' + params;
};
const loadLanguages = () => {
    api.get(
        route('ajax.get-languages', {
            _t: Date.now(),
        }),
    ).then((response) => {
        languageItems.value = response.languages;
        languageCurrent.value = response.languages.filter((x) => x.language ==  getLocale())[0]??null;
    }).catch((e) => {
        handleErrors(e);
    }).finally(() => {
    });
};
onMounted(() => {
    if ((languageItems.value??null) == null) {
        nextTick(() => {loadLanguages();});
    }
});
</script>

<style lang="scss">
#popup_menu_container:has(.mdi-web) {
    align-content: center;

    & .menu_trigger {
        font-size: 18pt;
    }
    & .popup_menu {
        width: 150px;
        left: calc(100% - 150px - 180px);
        &.mobile {
            left: calc(100% - 150px - 80px);
        }
        & li {
            padding: 5px;

            & .langtext {
                margin-left: 5px;
            }
        }
    }
    &:has(.login) {
        & .popup_menu {
            left: calc(100% - 200px);
        }
    }
}
</style>
<style lang="scss" scoped>
a:has(.fi) {
    width: 69px;
    gap:4px;
}
a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: var(--app-color-default);
    text-decoration: none;
    border-radius: 100px;
    transition: 0.15s ease-in-out all;

    & .fi {
        display:inline-block;
        width: 30px;
        height: 21px;
    }

    &.active {
        background: var(--app-color-blue);
        color: var(--app-color-white);
    }

    &:hover {
        background: var(--app-color-blue-background);
        color: var(--app-color-blue);
    }
}
</style>
