<template>
    <div id="popup_menu_container">
        <div class="menu_trigger" 
             @click="openMenu"
             @mouseover="mouseOverTriggerOrMenu=true, viewMenu ? openMenu : null"
             @mouseleave="mouseOverTriggerOrMenu=false, closeMenu(false)"
             @contextmenu="openMenu"
        >
            <slot name="trigger">
                <IButton
                    :icon="triggerIcon"
                    :label="triggerLabel"
                    :color="'primary'"
                    @click="openMenu"
                />
            </slot>
        </div>
        <ul :class="{popup_menu: true, mobile: mq.lgMinus}" 
            tabindex="-1" 
            ref="popup_menu"
            v-if="viewMenu" 
            @mouseover="mouseOverTriggerOrMenu=true, viewMenu ? openMenu : null"
            @mouseleave="mouseOverTriggerOrMenu=false, closeMenu(false)"
            @blur="closeMenu"
            :style="`top:${top.value}, left:${left.value}`"
        >
            <slot name="items" />
        </ul>
    </div>
</template>

<script setup>
import { ref, inject, nextTick } from 'vue';
import { useMq } from 'vue3-mq';
const mq = useMq();
const emitter = inject('emitter');
emitter.on('popupmenu:close', async () => {
    closeMenu(true);
});
emitter.on('popupmenu:open', (event) => {
    openMenu(event);
});

const props = defineProps({
    trigger: {
        type: String,
        required: false,
        default: 'click',
    },
    triggerLabel: {
        type: String,
        required: false,
        default: '',
    },
    triggerIcon: {
        type: String,
        required: false,
        default: 'plus',
    },
});
const top = ref(0);
const left = ref(0);
const viewMenu = ref(false);
const popup_menu = ref();
const mouseOverTriggerOrMenu = ref(false);

const closeMenu = (force) => {
    if (force) {
        viewMenu.value = false;
    } else {
        setTimeout(() => {
            if (!mouseOverTriggerOrMenu.value) {
                viewMenu.value = false;
            }
        }, 200);
    }
};

/**
 * props.trigger of caller = click, mouseover, contextmenu
 */
const openMenu = (e) => {
    if ((e.type == props.trigger) || mouseOverTriggerOrMenu.value) {
        viewMenu.value = true;
        nextTick(function() {
            e.currentTarget.focus();
        }.bind(this));
        e.preventDefault();
    }
};
</script>

<!-- scoped does not work ? #BDBDBD;-->
<style lang="scss">
#popup_menu_container .menu_trigger {
    cursor: pointer;
}
#popup_menu_container .popup_menu {
    background: var(--app-color-blue-background);
    color: var(--app-color-blue);
    border: 1px solid #BDBDBD;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
    display: block;
    list-style: none;
    margin: 0;
    padding: 1px;
    position: absolute;
    width: 300px;
    z-index: 999999;
    cursor: pointer;
}

#popup_menu_container .popup_menu li {
    border-bottom: 2px solid var(--app-color-white);
    margin: 0;
    padding: 5px 35px;
}

#popup_menu_container .popup_menu li:last-child {
    border-bottom: none;
}

#popup_menu_container .popup_menu li:hover {
    background: var(--app-color-blue-hover-background);
    color: var(--app-color-blue-hover);
}
#popup_menu_container .popup_menu li.disabled,
#popup_menu_container .popup_menu li:hover.disabled {
    background-color: var(--app-color-danger-background);
    color: var(--app-color-danger);
}
</style>
